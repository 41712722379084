import Link from "next/link";
import styles from "./404.module.scss";
import { PerspectiveCamera } from "@react-three/drei";
import Orb from "src/components/scenes/garage-scene/objects/orb/Orb";
import Button from "../../components/atoms/button/Button";
import useAnalytics from "../../hooks/useAnalytics";
import { MouseEvent } from "react";
import { Canvas } from "../../components/layout/canvas/Canvas";

export default function Page404() {
  const { trackLinkClick } = useAnalytics();

  function handleLinkClick(e: MouseEvent<HTMLAnchorElement>) {
    trackLinkClick(
      "internal",
      e.currentTarget.href,
      e.currentTarget.innerText,
      "other"
    );
  }
  return (
    <main className={styles.root}>
      <div className={styles.headingContainer}>
        <h1 className={styles.heading}>404</h1>
        <div className={styles.orb}>
          <Canvas>
            <PerspectiveCamera
              makeDefault
              fov={25}
              position={[0, 0, 7]}
              near={0.1}
              far={25}
            />
            <Orb scale={1.6} start />
          </Canvas>
        </div>
      </div>
      <p className={styles.paragraph}>
        Het lijkt erop dat je een verkeerde afslag hebt genomen en op een pagina
        die niet bestaat terecht bent gekomen
      </p>
      <div className={styles.buttonContainer}>
        <Link href="/" passHref legacyBehavior>
          <Button
            label="Naar Startpagina"
            buttonIcon="arrow-right"
            onClick={handleLinkClick}
          />
        </Link>
      </div>
    </main>
  );
}
